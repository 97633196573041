import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm } from "../utils/typography";
import Image from "gatsby-image";

class ProjectsPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const projects = require("../../content/projects.json");
    const oldProjects = require("../../content/old-projects.json");

    const generateProjectsComponent = projectsList =>
      projectsList.map(project => {
        const imageEdge = data.allFile.edges.filter(
          edge => edge.node.base === project.image
        );
        let image = imageEdge[0].node.childImageSharp.fluid;
        let alt = imageEdge[0].node.base;
        return (
          <div>
            <h2>{project.title}</h2>
            <Image
              fluid={image}
              alt={alt}
              style={{
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                minWidth: 50
              }}
              imgStyle={{}}
            />
            <div className="tags">
              {project.tags.split(",").map(tag => (
                <div className="tag">{tag.trim()}</div>
              ))}
            </div>
            <h3 style={{ marginBottom: `0.5em`, marginTop: `1em` }}>
              Description
            </h3>
            <span>{project.description}</span>
            <h3 style={{ marginBottom: `0.5em`, marginTop: `1em` }}>Links</h3>
            <ul>
              {project.website && (
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={project.website}
                  >
                    Website
                  </a>
                </li>
              )}{" "}
              {project.youtube && (
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={project.youtube}
                  >
                    Youtube
                  </a>
                </li>
              )}{" "}
              {project.github && (
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={project.github}
                  >
                    Github
                  </a>
                </li>
              )}
            </ul>
          </div>
        );
      });

    const projectsComponent = generateProjectsComponent(projects);
    const oldProjectsComponent = generateProjectsComponent(oldProjects);

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Projects" />
        <h1 style={{ textAlign: `center` }}>Projects</h1>
        <p>
          Programming projects created for fun and to learn new things. For the
          complete source code and description, check the corresponding GitHub
          repository.
        </p>
        {projectsComponent}
        <h1 style={{ textAlign: `center` }}>Old Projects</h1>
        <p>
          Old programming projects created for class assignments and/or that I
          simply think are cool.
        </p>
        {oldProjectsComponent}
      </Layout>
    );
  }
}

export default ProjectsPage;

export const pageQuery = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "projects" } }) {
      edges {
        node {
          id
          name
          base
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
